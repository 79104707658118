import * as React from 'react';
import * as Yup from 'yup';
import AuthServiceImpl from '../../components/auth/AuthServiceImpl';
import CmSnackbar from '../../components/snackbar/snackbar';
import Layout from '../../components/layout/Layout';
import Navbar from '../../components/navbar/navbar';
import Sentry from '../../components/error/sentry';
import SEO from '../../components/seo/seo';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { MDCTextField } from '@material/textfield';
import { SegmentProvider } from '../../components/analytics/SegmentProvider';
import { withIntl } from '../../i18n';
import './recovery.scss';
// import FormikDebug from '../signup/Debug';

declare global {
  interface Window {
    grecaptcha: any
    __REDUX_DEVTOOLS_EXTENSION__: any
    analytics: any
    drift: any
  }
}

class RecoveryPage extends React.Component {
  static propTypes: any

  public state: { email: string; showRecoveryError: any; showRecoverySuccess: any }

  private authService: AuthServiceImpl

  private analytics: SegmentProvider

  private submitHandler: any

  private emailRef: React.RefObject<any>

  private emailTextField: MDCTextField

  constructor(props) {
    super(props)
    this.authService = new AuthServiceImpl()
    this.authService.clearSessionCookies()
    this.authService.clearLocalStorage()
    this.emailRef = React.createRef()
    this.analytics = new SegmentProvider()
    this.submitHandler = this.handleSubmit.bind(this)
    this.state = { email: '', showRecoveryError: false, showRecoverySuccess: false }

    defineMessages({
      error: {
        id: 'recovery.error',
        defaultMessage:
          'Beim Zurücksetzen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut. Sollte der Fehler erneut auftauchen, kontaktieren Sie uns.',
      },
      success: {
        id: 'recovery.success',
        defaultMessage: 'Wir senden Ihnen in Kürze eine E-Mail zum Zurücksetzen des Passwortes, sofern die E-Mail einem Benutzerkonto zugeordnet ist.',
      },
    })
  }

  componentDidMount() {
    this.emailTextField = new MDCTextField(this.emailRef.current)
    this.emailTextField.useNativeValidation = false
    this.analytics.trackPageView()

    if (document.getElementById('recaptcha')) return
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js?render=' + process.env.GATSBY_CROPMATES_WEBSITE_GOOGLE_RECAPTCHA_ID
    script.async = true
    script.defer = true
    script.id = 'recaptcha'
    document.body.appendChild(script)
  }

  handleSubmit(values, bag: FormikProps<any>) {
    const self = this
    window.grecaptcha.ready(async () => {
      try {
        // const token = await window.grecaptcha.execute(process.env.GATSBY_CROPMATES_WEBSITE_GOOGLE_RECAPTCHA_ID, { action: 'recover' })
        await self.authService.requestResetPasswortEmail(values.email)
        self.setState({ showRecoverySuccess: true })
        self.setState({ showRecoverySuccess: false })
        self.setState({ email: '' })
        self.emailTextField.getDefaultFoundation().setValue('')
        bag.resetForm({ email: '' })
        bag.setSubmitting(false)
      } catch (err) {
        Sentry.captureException(new Error(err))
        self.setState({ showRecoveryError: true })
        self.setState({ showRecoveryError: false })
        bag.setSubmitting(false)
      }
    })

    bag.setSubmitting(false)
  }

  render() {
    return (
      <Layout>
        <SEO />
        <Navbar light={false} />
        <div className="mdc-layout-grid cm-content--min-height">
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-12">
              <h1 className="mdc-typography--headline2">
                <FormattedMessage id="recovery.title" defaultMessage="Geben Sie ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen" />
              </h1>
            </div>
            <Formik
              initialValues={this.state}
              validationSchema={() =>
                Yup.object().shape({
                  email: Yup.string()
                    .email('invalid')
                    .required('required'),
                })
              }
              onSubmit={this.submitHandler}
              render={({ handleChange, handleBlur, handleReset, isSubmitting, handleSubmit, values, errors, touched }: FormikProps<any>) => {
                return (
                  <>
                    <div className="mdc-layout-grid__cell--span-12">
                      <div className="mdc-layout-grid__inner">
                        <div className="mdc-layout-grid__cell--span-12">
                          <div
                            className="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon cm-text-field--l"
                            id="email"
                            ref={this.emailRef}
                          >
                            <i className="material-icons mdc-text-field__icon" tabIndex={-1} role="button">
                              email
                            </i>
                            <input
                              type="email"
                              id="email-input"
                              className="mdc-text-field__input"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              onReset={handleReset}
                            />
                            <label htmlFor="email-input" className={'mdc-floating-label ' + (values.email ? 'mdc-floating-label--float-above' : '')}>
                              <FormattedMessage id="recovery.email" defaultMessage="E-Mail" />
                            </label>
                            <div className="mdc-notched-outline">
                              <svg>
                                <path className="mdc-notched-outline__path" />
                              </svg>
                            </div>
                            <div className="mdc-notched-outline__idle" />
                          </div>
                          {errors.email && touched.email && (
                            <p
                              id="email-helper-text"
                              className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                              aria-hidden="true"
                            >
                              {errors.email == 'required' && (
                                <FormattedMessage id="recovery.error.email.required" defaultMessage="Ihre E-Mail-Adresse ist erforderlich" />
                              )}
                              {errors.email == 'invalid' && (
                                <FormattedMessage id="recovery.error.email.invalid" defaultMessage="Bitte geben Sie ein gültige E-Mail-Adresse an" />
                              )}
                            </p>
                          )}
                        </div>
                        <div className="mdc-layout-grid__cell--span-6 mdc-layout-grid__cell--align-right">
                          <button
                            id="cta-recovery"
                            className="mdc-button mdc-button--raised cm-button--primary"
                            type="submit"
                            onClick={handleSubmit as React.MouseEventHandler<any>}
                            disabled={isSubmitting}
                          >
                            <FormattedMessage id="recovery.cta" defaultMessage="Passwort zurücksetzen" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <FormikDebug /> */}
                  </>
                )
              }}
            />
          </div>
        </div>
        <CmSnackbar message="recovery.error" show={this.state.showRecoveryError} />
        <CmSnackbar message="recovery.success" show={this.state.showRecoverySuccess} />
      </Layout>
    )
  }
}

RecoveryPage.propTypes = {}

export default withIntl(RecoveryPage)
